// extracted by mini-css-extract-plugin
export var month = "calendar-module--month--2FGqr";
export var timeZone = "calendar-module--timeZone--4g_q4";
export var calendar = "calendar-module--calendar--2uqb4";
export var monday1st = "calendar-module--monday1st--2qoLQ";
export var tuesday1st = "calendar-module--tuesday1st--cgFDH";
export var wednesday1st = "calendar-module--wednesday1st--2mGZi";
export var thursday1st = "calendar-module--thursday1st--MiDek";
export var friday1st = "calendar-module--friday1st--2vz5K";
export var saturday1st = "calendar-module--saturday1st--3kxt2";
export var sunday1st = "calendar-module--sunday1st--XW98K";